<template>
  <div class="p-4 bg-white rounded-lg" v-if="isDataLoaded">
    <DataTable />
  </div>
</template>

<script>
import DataTable from "@/own/components/shipping/returns/DataTable.vue";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/returns.module";

export default {
  name: "Returns",
  components: { DataTable },
  methods: {
    async initTableContent() {
      await this.$store.dispatch(UPDATE_TABLE_STRUCTURE);
      await this.$store.dispatch(UPDATE_TABLE_DATA, {}).finally(() => {
        this.$store.commit(SET_PAGE_LOADING, false);
      });
    },
  },
  computed: {
    isDataLoaded: function () {
      return this.$store.getters.getRTRNTableData;
    },
  },
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
    this.initTableContent();
  },
};
</script>
