<template>
  <div>
    <div class="row">
      <div class="col-12 col-sm-4">
        <v-text-field
          v-model="collection_address.name"
          class
          label="Name"
          clearable
          outlined
          dense
          :error-messages="collectionNameErrors"
          @input="$v.collection_address.name.$touch()"
          @blur="$v.collection_address.name.$touch()"
        ></v-text-field>
      </div>
      <div class="col-12 col-sm-4">
        <v-text-field
          class
          v-model="collection_address.company"
          label="Company"
          clearable
          outlined
          dense
        ></v-text-field>
      </div>
      <div class="col-12 col-sm-4">
        <v-autocomplete
          @change="onCollectionCountryChange"
          v-model="collection_address.country"
          :label="getSuggestedRegion('country')"
          item-text="text"
          item-value="index"
          :items="serverData.countries"
          clearable
          outlined
          dense
          :error-messages="collectionCountryErrors"
          @input="$v.collection_address.country.$touch()"
          @blur="$v.collection_address.country.$touch()"
        ></v-autocomplete>
      </div>

      <div class="col-12 col-sm-4">
        <v-combobox
          ref="stateCombo"
          v-model="collection_address.state"
          :search-input.sync="collection_state_text"
          :items="collectionAddressServerData.states"
          :disabled="isCollectionStatesLoaded"
          :rules="
            validationValueCheck('collection_state')
              ? [validationValueCheck('collection_state')]
              : []
          "
          item-text="title"
          item-value="title"
          :label="getSuggestedRegion('state')"
          persistent-hint
          outlined
          clearable
          dense
          :error-messages="collectionStateErrors"
          @input="$v.collection_address.state.$touch()"
          @blur="$v.collection_address.state.$touch()"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  No results matching "<strong>{{
                    collection_state_text
                  }}</strong
                  >".
                  <button
                    class="btn btn-info pl-2"
                    @click="
                      createNewItem(
                        'state',
                        collection_address.country,
                        onCollectionCountryChange,
                        collection_state_text
                      )
                    "
                  >
                    Create new
                  </button>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-combobox>
      </div>
      <div class="col-12 col-sm-4">
        <v-combobox
          ref="cityCombo"
          v-model="collection_address.city"
          :search-input.sync="collection_city_text"
          :items="collectionAddressServerData.cities"
          :disabled="isCollectionCitiesLoaded"
          :rules="
            validationValueCheck('collection_city')
              ? [validationValueCheck('collection_city')]
              : []
          "
          item-text="title"
          item-value="title"
          :label="getSuggestedRegion('city')"
          persistent-hint
          outlined
          dense
          clearable
          :error-messages="collectionCityErrors"
          @input="$v.collection_address.city.$touch()"
          @blur="$v.collection_address.city.$touch()"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  No results matching "<strong>{{
                    collection_city_text
                  }}</strong
                  >".
                  <button
                    class="btn btn-info pl-2"
                    @click="
                      createNewItem(
                        'city',
                        collection_address.state.id,
                        onCollectionStateChange,
                        collection_city_text
                      )
                    "
                  >
                    Create new
                  </button>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-combobox>
      </div>
      <div class="col-12 col-sm-4">
        <v-combobox
          ref="areaCombo"
          v-model="collection_address.area"
          :search-input.sync="collection_area_text"
          :items="collectionAddressServerData.areas"
          :rules="
            validationValueCheck('collection_area')
              ? [validationValueCheck('collection_area')]
              : []
          "
          item-text="title"
          item-value="title"
          :label="getSuggestedRegion('area')"
          persistent-hint
          outlined
          :disabled="isCollectionAreasLoaded"
          dense
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  No results matching "<strong>{{
                    collection_area_text
                  }}</strong
                  >".
                  <button
                    class="btn btn-info pl-2"
                    @click="
                      createNewItem(
                        'area',
                        collection_address.city.id,
                        onCollectionCityChange,
                        collection_area_text
                      )
                    "
                  >
                    Create new
                  </button>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-combobox>
      </div>
      <div class="col-12 col-sm-4">
        <v-text-field
          v-model="collection_address.address"
          label="Address"
          clearable
          outlined
          dense
          :error-messages="collectionAddressErrors"
          @input="$v.collection_address.address.$touch()"
          @blur="$v.collection_address.address.$touch()"
        ></v-text-field>
      </div>
      <div class="col-12 col-sm-4">
        <v-text-field
          v-model="collection_address.address_2"
          label="Address 2"
          clearable
          outlined
          dense
        ></v-text-field>
      </div>
      <div class="col-12 col-sm-4">
        <VueTelInputVuetify
          v-model="collection_address.phone"
          label="Phone"
          type="number"
          clearable
          outlined
          dense
          :inputOptions="{ showDialCode: false, tabindex: 0 }"
          mode="international"
          disabledFetchingCountry
          defaultCountry="AE"
          :error-messages="collectionPhoneErrors"
          @input="$v.collection_address.phone.$touch()"
          @blur="$v.collection_address.phone.$touch()"
        ></VueTelInputVuetify>
      </div>
    </div>
    <CreateLocation
      :location-id="getLocationId"
      :location-type="getLocationType"
      :location-function="getLocationFunc"
      :locationSetValue="setTextValue"
      :initValue="getLocationInitValue"
      ref="location"
    ></CreateLocation>
  </div>
</template>
<script>
import CreateLocation from "@/own/components/shipping/returns/external/CreateLocation.vue";

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

import {
  handleCityValueChange,
  handleCountryValueChange,
  handleStateValueChange,
} from "@/own/libs/handle-locations";

export default {
  name: "CollectionAddress",
  mixins: [validationMixin],
  components: { CreateLocation, VueTelInputVuetify },
  validations() {
    return {
      collection_address: {
        name: { required },
        address: { required },
        city: { required },
        country: { required },
        phone: { required },
        state: { required },
      },
    };
  },
  data: () => {
    return {
      collection_address: {
        name: null,
        company: null,
        address: null,
        area: null,
        city: null,
        country: null,
        phone: null,
        state: null,
        building_no: null,
        unit_no: null,
        additional_no: null,
      },

      location_type: null,
      locaiton_id: null,
      location_function: null,

      collection_state_text: "",
      collection_city_text: "",
      collection_area_text: "",

      collectionAddressServerData: {},
      updateValueTimer: setTimeout(() => {}, 0),
      locationFetchTriggerTime: 600,
    };
  },
  computed: {
    getLocationId() {
      return this.locaiton_id;
    },
    getLocationType() {
      return this.location_type;
    },
    getLocationFunc() {
      return this.location_function;
    },
    collectionNameErrors: function () {
      return this.handleFormValidation("name", this, "collection_address");
    },
    collectionAddressErrors: function () {
      return this.handleFormValidation("address", this, "collection_address");
    },
    collectionCityErrors: function () {
      return this.handleFormValidation("city", this, "collection_address");
    },
    collectionPhoneErrors: function () {
      return this.handleFormValidation("phone", this, "collection_address");
    },
    collectionMobileErrors: function () {
      return this.handleFormValidation("mobile", this, "collection_address");
    },
    collectionCountryErrors: function () {
      return this.handleFormValidation("country", this, "collection_address");
    },
    collectionStateErrors: function () {
      return this.handleFormValidation("state", this, "collection_address");
    },
    collectionBuildingNoErrors: function () {
      return this.handleFormValidation(
        "building_no",
        this,
        "collection_address"
      );
    },
    collectionUnitNoErrors: function () {
      return this.handleFormValidation("unit_no", this, "collection_address");
    },
    collectionAdditionalNoErrors: function () {
      return this.handleFormValidation(
        "additional_no",
        this,
        "collection_address"
      );
    },
    getLocationInitValue() {
      return this.location_initValue;
    },
    serverData: function () {
      return this.$store.getters.getRTRNServerData;
    },
    isCollectionAreasLoaded: function () {
      return !this.collectionAddressServerData.areas;
    },
    isCollectionCitiesLoaded: function () {
      return !this.collectionAddressServerData.cities;
    },
    isCollectionStatesLoaded: function () {
      return !this.collectionAddressServerData.states;
    },
    isFormValid: function () {
      return !this.$v.$invalid;
    },
    editData() {
      return this.$store.getters.getRTRNEditData;
    },
  },
  methods: {
    updateValue(type) {
      clearTimeout(this.updateValueTimer);
      this.updateValueTimer = setTimeout(() => {
        this.onBlurValueCheck(type);
      }, this.locationFetchTriggerTime);
    },
    pageLoader(val = true) {
      this.$store.commit(SET_PAGE_LOADING, val);
    },
    async onCollectionCountryChange(val, safe = true) {
      this.pageLoader(true);
      try {
        await handleCountryValueChange.call(
          this,
          val,
          safe,
          "collection_address",
          "collectionAddressServerData",
          this.serverData.return_management.organization_id
        );
        await this.updateValue("collection_state");
      } catch {
        this.pageLoader(false);
      } finally {
        this.pageLoader(false);
      }
    },
    async onCollectionStateChange(val, safe = true, is_id = false) {
      this.pageLoader(true);
      try {
        await handleStateValueChange.call(
          this,
          val,
          safe,
          is_id,
          "collection_address",
          "collectionAddressServerData",
          this.serverData.return_management.organization_id
        );
        await this.updateValue("collection_city");
      } catch {
        this.pageLoader(false);
      } finally {
        this.pageLoader(false);
      }
    },
    async onCollectionCityChange(val, safe = true, is_id = false) {
      this.pageLoader(true);
      try {
        await handleCityValueChange.call(
          this,
          val,
          safe,
          is_id,
          "collection_address",
          "collectionAddressServerData",
          this.serverData.return_management.organization_id
        );
        this.updateValue("collection_area");
      } catch {
        this.pageLoader(false);
      } finally {
        this.pageLoader(false);
      }
    },
    validationValueCheck(type) {
      let validation = false;
      switch (type) {
        case "collection_state":
          if (this.collection_state_text == "") {
            return;
          }
          try {
            if (
              !this.collectionAddressServerData?.states?.find(
                (state) => state.title === this.collection_state_text
              )
            ) {
              validation = "Please, select valid state!";
              // this.serverData.cities = null;
              // this.serverData.areas = null;
            }
          } catch {
            validation = "Please, select valid state!";
          }

          break;

        case "collection_city":
          if (this.collection_city_text == "") {
            return;
          }
          try {
            if (
              !this.collectionAddressServerData?.cities?.find(
                (city) => city.title === this.collection_city_text
              )
            ) {
              validation = "Please, select valid city!";
              // this.serverData.areas = null;
            }
          } catch {
            validation = "Please, select valid state!";
          }

          break;

        case "collection_area":
          try {
            if (
              !this.collectionAddressServerData.areas.find(
                (area) => area.title === this.collection_area_text
              )
            ) {
              validation = "Please, select valid area!";
            }
          } catch {
            validation = "Please, select valid city!";
          }
          break;
      }
      return validation;
    },
    async onBlurValueCheck(type) {
      switch (type) {
        case "collection_state": {
          if (this.collection_state_text == "") {
            return;
          }

          const item = this.collectionAddressServerData?.states?.find(
            (state) =>
              state?.title?.toLowerCase().replace(/[^a-zA-Z0-9]/g, "") ===
              this.collection_state_text
                .toLowerCase()
                .replace(/[^a-zA-Z0-9]/g, "")
          );
          if (!item) {
            this.collectionAddressServerData.cities = null;
            this.collectionAddressServerData.areas = null;
          } else {
            if (item.title != this.collection_state_text) {
              this.collection_state_text = item.title;
            } else {
              await this.onCollectionStateChange(item.title);
              this.$refs.stateCombo.blur();
            }
          }
          break;
        }
        case "collection_city": {
          if (this.collection_city_text == "") {
            return;
          }

          const item = this.collectionAddressServerData?.cities?.find(
            (city) =>
              city?.title?.toLowerCase().replace(/[^a-zA-Z0-9]/g, "") ===
              this.collection_city_text
                ?.toLowerCase()
                .replace(/[^a-zA-Z0-9]/g, "")
          );
          if (!item) {
            this.collectionAddressServerData.areas = null;
          } else {
            if (item.title != this.collection_city_text) {
              this.collection_city_text = item.title;
            } else {
              await this.onCollectionCityChange(item.title);
              this.$refs.cityCombo.blur();
            }
          }
          break;
        }
        case "collection_area": {
          const foundArea = this.collectionAddressServerData.areas.find(
            (area) =>
              area?.title?.toLowerCase().replace(/[^a-zA-Z0-9]/g, "") ===
              this.collection_area_text
          );
          if (!foundArea) {
            setTimeout(() => {
              // this.area_text = "";
            }, 2000);
          } else {
            this.collection_address.area = foundArea;
          }
          break;
        }
      }
    },
    async setTextValue(type, value = "") {
      switch (type) {
        case "collection_state": {
          this.collection_state_text = value;
          if (value == "") {
            return;
          }
          const state = this.collectionAddressServerData?.states?.find(
            (state) =>
              state.title.toLowerCase().replace(/[^a-zA-Z0-9]/g, "") ===
              this.collection_state_text
                ?.toLowerCase()
                .replace(/[^a-zA-Z0-9]/g, "")
          );
          this.collection_address.state = state;
          // this.onStateChange(this.address.state.title);

          break;
        }
        case "collection_city": {
          this.collection_city_text = value;
          if (value == "") {
            return;
          }
          const city = this.collectionAddressServerData?.cities?.find(
            (city) =>
              city.title.toLowerCase().replace(/[^a-zA-Z0-9]/g, "") ===
              this.collection_city_text
                ?.toLowerCase()
                .replace(/[^a-zA-Z0-9]/g, "")
          );
          if (city) {
            this.collection_address.city = city;
          }

          // this.onCityChange(this.address.city.title);

          break;
        }
        case "collection_area": {
          this.collection_area_text = value;

          if (value == "") {
            return;
          }
          const area = this.collectionAddressServerData?.areas?.find(
            (area) =>
              area.title.toLowerCase().replace(/[^a-zA-Z0-9]/g, "") ===
              this.collection_area_text
                .toLowerCase()
                .replace(/[^a-zA-Z0-9]/g, "")
          );
          if (area) {
            this.collection_address.area = area;
          }

          break;
        }
      }
    },
    handleFormValidation(fieldName, vueObj, dataName = "formData") {
      const errors = [];
      if (!vueObj.$v[`${dataName}`][fieldName].$dirty) return errors;
      if ("required" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].required &&
          errors.push("This field is required");
      }
      if ("maxValue" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].maxValue &&
          errors.push("This field must be greater than 0");
      }
      if ("url" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
    createNewItem(type, id, func, value = "") {
      this.location_type = type;
      this.locaiton_id = id;
      this.location_function = func;
      this.location_initValue = value;
      this.$nextTick(() => {
        this.$refs.location.toggleModal();
      });
    },
    checkErrors() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
    },
    clearFields() {
      this.collection_address = {
        name: null,
        company: null,
        address: null,
        area: null,
        city: null,
        country: null,
        phone: null,
        state: null,
        building_no: null,
        unit_no: null,
        additional_no: null,
      };
      this.location_type = null;
      this.locaiton_id = null;
      this.location_function = null;

      this.collection_state_text = null;
      this.collection_city_text = null;
      this.collection_area_text = null;

      this.collectionAddressServerData = {};

      //reset validation
      this.$v.$reset();
    },
    async setDeafultCollectionAddress() {
      this.collection_address.country = 229;
      let state = null;
      let city = null;

      await this.onCollectionCountryChange(229);
      state = this.collectionAddressServerData.states.find(
        (item) => item.id === 4182
      );

      await this.onCollectionStateChange(state, true);
      city = this.collectionAddressServerData.cities.find(
        (item) => item.id === 48468
      );
      await this.onCollectionCityChange(city, true);

      this.collection_address = {
        ...this.collection_address,
        state,
        city,
      };
    },
    async getEditData() {
      const data = this.editData.collectionAddress;
      this.collection_address = {
        ...this.editData.collectionAddress,
      };
      if (data.country) {
        await this.onCollectionCountryChange(data.country, true);
        // .then(() => {
        //   this.onCollectionStateChange(data.state, true, true).then(() => {
        //     this.onCollectionCityChange(data.city, true, true);
        //   });
        // });

        // if (this.serverData.order.state) {
        //   await this.onStateChange(this.serverData.order.state);
        // }
        // if (this.serverData.order.city) {
        //   await this.onCityChange(this.serverData.order.city);
        // }
      }
    },
    getSuggestedRegion(type) {
      const typeCapitalize = type.charAt(0).toUpperCase() + type.slice(1);

      if (
        this.serverData.return_management &&
        this.serverData.return_management[`c_${type}`]
      ) {
        return `${typeCapitalize} (Suggested: ${
          this.serverData.return_management[`c_${type}`]
        })`;
      } else {
        return typeCapitalize;
      }
    },
  },
  watch: {
    collection_state_text: {
      handler(value, oldValue) {
        if (value == "" || value == null || value == undefined) {
          return;
        }
        if (value == oldValue) {
          return;
        }
        this.updateValue("collection_state");
      },
      deep: true,
    },
    collection_city_text: {
      handler(value, oldValue) {
        if (!this.collectionAddressServerData.states) {
          this.collectionAddressServerData.cities = null;
          this.collectionAddressServerData.areas = null;
          return;
        }
        if (value == "" || value == null || value == undefined) {
          return;
        }
        if (value == oldValue) {
          return;
        }
        this.updateValue("collection_city");
      },
      deep: true,
    },
  },
};
</script>
