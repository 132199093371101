<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="600"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Create Return ASN</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--begin::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <div class="row">
          <v-col cols="12">
            <v-select
              v-model="formData.warehouse_id"
              label="Warehouse"
              clearable
              outlined
              dense
              item-text="text"
              item-value="index"
              :items="warehouses"
              :error-messages="warehouseErrors"
              @input="$v.formData.warehouse_id.$touch()"
              @blur="$v.formData.warehouse_id.$touch()"
            ></v-select>
          </v-col>
        </div>
        <!--end::Body-->

        <!--begin::Actions-->
        <div class="my-2 d-flex flex-grow-1 align-end justify-center">
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submit"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

export default {
  name: "CreateReturnAsnModal",
  mixins: [validationMixin],
  props: ["items", "updateTableContent"],
  validations() {
    return {
      formData: {
        warehouse_id: { required },
      },
    };
  },
  data: () => ({
    dialog: false,
    formData: {
      warehouse_id: null,
    },
  }),
  methods: {
    handleFormValidation(fieldName, vueObj, dataName = "formData") {
      const errors = [];
      if (!vueObj.$v[`${dataName}`][fieldName].$dirty) return errors;
      if ("required" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].required &&
          errors.push("This field is required");
      }
      if ("maxValue" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].maxValue &&
          errors.push("This field must be greater than 0");
      }
      if ("url" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
    toggleModal() {
      this.reset();
      this.dialog = !this.dialog;
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$store.commit(SET_PAGE_LOADING, true);
      const payload = {
        ids: this.items.map((item) => item.id),
        warehouse_id: this.formData.warehouse_id,
      };

      ApiService.post(`/shipping/returns/return_order_asn_create`, payload)
        .then(({ data }) => {
          let editedItemMessage = `<div><ol>`;
          data.messages.forEach((item) => {
            editedItemMessage += `<li>${item}</li>`;
          });
          editedItemMessage = editedItemMessage + `</ol></div>`;
          Swal.fire({
            title: "Success",
            html: `${editedItemMessage}`,
            icon: data.error_orders === 0 ? "success" : "warning",
            showConfirmButton: true,
            // timer: 2500,
          });
          this.toggleModal();
          this.updateTableContent();
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },

    reset() {
      this.formData = {
        warehouse_id: null,
      };
      this.$v.$reset();
    },
  },
  computed: {
    warehouseErrors: function () {
      return this.handleFormValidation("warehouse_id", this, "formData");
    },
    warehouses: function () {
      return this.$store.getters.getRTRNDestinationWarehouses;
    },
  },
  mounted() {},
};
</script>
