<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="600"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Update Destination Address</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--begin::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <div class="row">
          <v-col cols="12">
            <v-select
              v-model="types.destination_address_type"
              label="Destination Address Type"
              item-text="title"
              item-value="index"
              :items="destinationAddressTypes"
              clearable
              outlined
              dense
              :error-messages="destinationTypeErros"
              @input="$v.types.destination_address_type.$touch()"
              @blur="$v.types.destination_address_type.$touch()"
            ></v-select>
          </v-col>
          <!-- if destination address type is carrier -->
          <v-col
            v-if="
              types.destination_address_type === 1 ||
              types.destination_address_type === null
            "
            cols="12"
            class="mt-n7"
          >
            <v-autocomplete
              v-model="formData.destination_carrier_address_id"
              label="Destination Address"
              item-text="text"
              item-value="index"
              :items="destinationAddressSuggestions"
              clearable
              outlined
              dense
              @change="onChangeDestinationAddress"
              :error-messages="destinationCarrierAddressErrors"
              @input="$v.formData.destination_carrier_address_id.$touch()"
              @blur="$v.formData.destination_carrier_address_id.$touch()"
            >
            </v-autocomplete>
          </v-col>
          <!-- if destination address type is destination -->
          <v-col
            v-if="types.destination_address_type === 2"
            cols="12"
            class="mt-n7"
          >
            <v-autocomplete
              v-model="formData.destination_warehouse_id"
              label="Destination Address"
              item-text="text"
              item-value="index"
              :items="destinationWarehouseSuggestions"
              clearable
              outlined
              dense
              @change="onChangeDestinationAddress"
              :error-messages="destinationWarehouseAddressErrors"
              @input="$v.formData.destination_warehouse_id.$touch()"
              @blur="$v.formData.destination_warehouse_id.$touch()"
            >
            </v-autocomplete>
          </v-col>
          <!-- if destination address type is customer warehouse -->
          <v-col
            v-if="types.destination_address_type === 3"
            cols="12"
            class="mt-n7"
          >
            <v-autocomplete
              v-model="formData.destination_customer_address_id"
              label="Destination Address"
              item-text="text"
              item-value="index"
              :items="destinationCustomerAddressesSuggestions"
              clearable
              outlined
              dense
              @change="onChangeDestinationAddress"
              :error-messages="destinationCustomerAddressErrors"
              @input="$v.formData.destination_customer_address_id.$touch()"
              @blur="$v.formData.destination_customer_address_id.$touch()"
            >
            </v-autocomplete>
          </v-col>
        </div>
        <!--end::Body-->

        <!--begin::Actions-->
        <div class="my-2 d-flex flex-grow-1 align-end justify-center">
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submit"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

export default {
  name: "BulkEditDestination",
  mixins: [validationMixin],
  props: ["items", "updateTableContent"],
  validations() {
    return {
      formData: {
        destination_carrier_address_id: {
          required: requiredIf(function () {
            return this.types.destination_address_type === 1;
          }),
        },
        destination_warehouse_id: {
          required: requiredIf(function () {
            return this.types.destination_address_type === 2;
          }),
        },
        destination_customer_address_id: {
          required: requiredIf(function () {
            return this.types.destination_address_type === 3;
          }),
        },
      },
      types: {
        destination_address_type: { required },
      },
    };
  },
  data: () => ({
    dialog: false,
    types: {
      destination_address_type: null,
    },
    formData: {
      destination_carrier_address_id: null,
      destination_warehouse_id: null,
      destination_customer_address_id: null,
    },
  }),
  methods: {
    handleFormValidation(fieldName, vueObj, dataName = "formData") {
      const errors = [];
      if (!vueObj.$v[`${dataName}`][fieldName].$dirty) return errors;
      if ("required" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].required &&
          errors.push("This field is required");
      }
      if ("maxValue" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].maxValue &&
          errors.push("This field must be greater than 0");
      }
      if ("url" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
    toggleModal() {
      this.reset();
      this.dialog = !this.dialog;
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$store.commit(SET_PAGE_LOADING, true);
      const types = {
        1: "carrier",
        2: "warehouse",
        3: "customer",
      };
      const payload = {
        ids: this.items.map((item) => item.id),
        address_type: types[this.types.destination_address_type],
        destination_carrier_address_id:
          this.formData.destination_carrier_address_id,
        destination_warehouse_id: this.formData.destination_warehouse_id,
        destination_customer_address_id:
          this.formData.destination_customer_address_id,
      };
      ApiService.post(`shipping/returns/bulk-edit-destination-address`, payload)
        .then(({ data }) => {
          Swal.fire({
            title: "Success",
            text: `${data.message}`,
            icon: "success",
            showConfirmButton: true,
            // timer: 2500,
          });
          this.toggleModal();
          this.updateTableContent();
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    onChangeDestinationAddress() {
      const type = this.types.destination_address_type;
      if (type === 1) {
        this.formData.destination_warehouse_id = null;
        this.formData.destination_customer_address_id = null;
      } else if (type === 2) {
        this.formData.destination_carrier_address_id = null;
        this.formData.destination_customer_address_id = null;
      } else if (type === 3) {
        this.formData.destination_carrier_address_id = null;
        this.formData.destination_warehouse_id = null;
      }
    },
    reset() {
      this.types = {
        destination_address_type: null,
      };
      this.formData = {
        destination_carrier_address_id: null,
        destination_warehouse_id: null,
        destination_customer_address_id: null,
      };
      this.$v.$reset();
    },
  },
  computed: {
    carriers: function () {
      return this.$store.getters.getRTRNCarriers;
    },
    destinationAddressTypes: function () {
      return this.$store.getters.getRTRNDestinationAddressType;
    },
    destinationCustomerAddressesSuggestions: function () {
      return this.$store.getters.getRTRNDestinationCustomerAddresses;
    },
    destinationCarriersSuggestions: function () {
      return this.$store.getters.getRTRNCarriers;
    },
    destinationTypeErros: function () {
      return this.handleFormValidation(
        "destination_address_type",
        this,
        "types"
      );
    },
    destinationAddressSuggestions: function () {
      return this.$store.getters.getRTRNDestinationCarrierAddersses;
    },
    destinationWarehouseSuggestions: function () {
      return this.$store.getters.getRTRNDestinationWarehouses;
    },
    destinationCarrierAddressErrors: function () {
      return this.handleFormValidation(
        "destination_carrier_address_id",
        this,
        "formData"
      );
    },
    destinationWarehouseAddressErrors: function () {
      return this.handleFormValidation(
        "destination_warehouse_id",
        this,
        "formData"
      );
    },
    destinationCustomerAddressErrors: function () {
      return this.handleFormValidation(
        "destination_customer_address_id",
        this,
        "formData"
      );
    },
  },
  mounted() {},
};
</script>
