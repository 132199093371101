/**
 * Finds an object in an array of objects based on a specified property value.
 *
 * @param {string | number | object} searchTargetValue The value to search for in the array of objects.
 * @param {string} propertyToCheck The name of the property to compare with the search value. (default: 'id')
 * @param {Array<object>} objectsToSearch The array of objects to search through.
 * @returns {object | null} The matching object or null if no matching object is found.
 */
function findObjectInArray(
  searchTargetValue,
  propertyToCheck = "id",
  objectsToSearch
) {
  if (!searchTargetValue || !objectsToSearch || objectsToSearch.length === 0) {
    return null;
  }

  let foundItem;

  try {
    foundItem = objectsToSearch.find(
      (obj) => obj[propertyToCheck] == searchTargetValue
    );
  } catch {
    return null;
  }
  return foundItem;
}

function findObjectInArrayWithFormatting(
  searchTargetValue,
  propertyToCheck = "id",
  objectsToSearch
) {
  if (!searchTargetValue || !objectsToSearch || objectsToSearch.length === 0) {
    return null;
  }

  let foundItem;

  try {
    foundItem = objectsToSearch.find(
      (obj) =>
        obj[propertyToCheck].toLowerCase().replace(/[^a-zA-Z0-9]/g, "") ==
        searchTargetValue.toLowerCase().replace(/[^a-zA-Z0-9]/g, "")
    );
  } catch {
    return null;
  }
  return foundItem;
}

export { findObjectInArray, findObjectInArrayWithFormatting };
